import config from 'web-app/config/environment';
import Service from '@ember/service';
import Plausible from 'plausible-tracker';

export default class AnalyticsService extends Service {
  plausible?: ReturnType<typeof Plausible>;

  enable() {
    if (config.environment !== 'production') return;
    if (window.location.host.includes('staging')) return;
    this.plausible = Plausible({
      domain: 'arianwellbeing.com',
      apiHost: 'https://watson.arianwellbeing.com',
    });
    this.plausible.enableAutoPageviews();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
