import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface XFormMultipleChoiceAnswersArgs {}

export default class XFormMultipleChoiceAnswers extends Component<XFormMultipleChoiceAnswersArgs> {
  get guid() {
    return guidFor(this);
  }
}
