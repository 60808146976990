import HistoryLocation from '@ember/routing/history-location';

export default class HistoryLocationTrailing extends HistoryLocation {
  formatURL() {
    const url = super.formatURL(...arguments);

    if (url.includes('#')) {
      return url.replace(/([^/])#(.*)/, '$1/#$2');
    }

    if (url.includes('?')) {
      return url.replace(/([^/])\?(.*)/, '$1/?$2');
    }

    return url.replace(/\/?$/, '/');
  }
}
