import { FetchError } from 'web-app/services/api';
import Component from '@glimmer/component';
import { isArray } from '@ember/array';
import { action } from '@ember/object';
import { Registry, service } from '@ember/service';

// interface FormsContactArgs {}

export default class FormsContact extends Component /*<FormsContactArgs>*/ {
  @service api!: Registry['api'];

  formValid(form: ContactFormDom) {
    const errors = [];

    if (!form.data.name || form.data.name.trim() === '') {
      errors.push('Your name is required');
    }

    if (!form.data.email || form.data.email.trim() === '') {
      errors.push('Your email is required');
    }

    if (!form.data.message || form.data.message.trim() === '') {
      errors.push('Your message is required');
    }

    const reasons: ContactReason[] = [
      'clientAssistance',
      'billingQuery',
      'professionalEnlistment',
      'pressEnquiry',
      'organisationalInterest',
      'generalQuery',
    ];

    if (!form.data.reason || form.data.reason.trim() === '') {
      errors.unshift('Please select a reason for contacting us');
      return { success: false, message: 'Please review the errors below before continuing', errors };
    }

    if (!reasons.includes(form.data.reason)) {
      errors.push('Reason is invalid');
    }

    if (errors.length > 0) {
      return { success: false, message: 'Please review the errors below before continuing', errors };
    }

    return { success: true };
  }

  @action
  async submit(formData: ContactFormDom): Promise<FormResult> {
    const isValid = this.formValid(formData);
    if (isValid.success) {
      try {
        await this.api.sendContactEmail(formData.data);

        return { success: true };
      } catch (error) {
        if (error instanceof FetchError) {
          const errors = [];

          if (error?.json?.message) errors.push(<string>error.json.message);

          if (error.json.errors) {
            if (isArray(error.json.errors)) {
              const errorList = <Array<{ message: string }>>error.json.errors;
              errorList.forEach((e) => errors.push(e.message));
            }
          }

          const genericMessage = 'We could not send your message, please check the form and try again.';

          if (errors.length === 0) {
            errors.push(genericMessage);
          }
          return {
            success: false,
            errors,
            message: genericMessage,
          };
        }
      }
    }
    return isValid;
  }
}
