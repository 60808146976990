import { next } from '@ember/runloop';
import { modifier } from 'ember-modifier';

const DEFAULT_SELECTOR = 'input:not([disabled]):not([readonly]),textarea:not([disabled]):not([readonly])';

interface NamedArguments {
  disabled?: boolean;
}

export default modifier(function autofocus(
  element: HTMLFormElement,
  [selector = DEFAULT_SELECTOR]: [string],
  { disabled }: NamedArguments
) {
  if (disabled) {
    return;
  }

  const childElement: HTMLFormElement | null = element.querySelector(selector);

  next(() => {
    const el = childElement || element;
    // el.focus();
    next(() => el.focus());
  });
});
