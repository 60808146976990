import { FetchError } from 'web-app/services/api';
import Component from '@glimmer/component';

interface XFormErrorsArgs {
  status: FormResult | FetchError;
}

interface ServerError {
  errors: Array<{
    message: string;
  }>;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class XFormErrors extends Component<XFormErrorsArgs> {
  get status() {
    if (this.args.status instanceof FetchError) {
      const templatedErrors: { errors: string[]; message: string } = {
        errors: [],
        message: 'Sorry, there was a problem with your submission',
      };

      const jsonErrors = this.args.status.json as unknown as ServerError;
      if (jsonErrors.errors) {
        jsonErrors.errors.forEach((error) => {
          templatedErrors.errors.push(error.message);
        });
      } else {
        // @ts-expect-error: Request throttler errors are unique
        if (jsonErrors.code === 'E_LIMIT_EXCEPTION' && jsonErrors.message) {
          // @ts-expect-error: Request throttler errors are unique
          templatedErrors.errors.push(jsonErrors.message);
        }
      }

      return templatedErrors;
    }
    return this.args.status;
  }
}
