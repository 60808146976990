import Route from '@ember/routing/route';

export default class CreatePassword extends Route {
  async model({ email, token }: { email: string; token: string }) {
    return {
      email,
      token,
    };
  }
}
