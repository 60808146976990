import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Registry, service } from '@ember/service';
import styles from './styles.css';

interface NavigationAppArgs {
  showLinks: boolean;
  dark: boolean;
}

export default class NavigationApp extends Component<NavigationAppArgs> {
  @service session!: Registry['session'];
  @service api!: Registry['api'];
  styles = styles;

  @action
  async logout(): Promise<void> {
    if (!this.session.isAuthenticated) return;
    try {
      await this.api.logout(<string>this.session.data.authenticated.refreshToken);
    } catch {
      //swallow
    }
    await this.session.invalidate();
  }
}
