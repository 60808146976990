import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import styles from '../styles.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface XFormMultipleChoiceOptionArgs {}

export default class XFormMultipleChoiceOption extends Component<XFormMultipleChoiceOptionArgs> {
  styles = styles;
  get guid() {
    return guidFor(this);
  }
}
