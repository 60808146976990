import Route from '@ember/routing/route';

export default class VerifyEmail extends Route {
  async model({ email, signature }: { email: string; signature: string }) {
    return {
      email,
      signature,
    };
  }
}
