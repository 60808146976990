import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { cancel, later, schedule } from '@ember/runloop';
import { EmberRunTimer } from '@ember/runloop/types';
import { task } from 'ember-concurrency';
import pMinDelay from 'p-min-delay';
import styles from './styles.css';

interface SubmitFunction {
  (arg0: FormInfo): Promise<FormResult>;
}
interface XFormArgs {
  onSubmit?: SubmitFunction;
  honeypot?: boolean;
  minTime: number;
  noReset: boolean;
}
export default class XFormComponent extends Component<XFormArgs> {
  styles = styles;
  @tracked errors = [];
  timer?: EmberRunTimer;

  async submit(formInfo: FormInfo): Promise<FormResult> {
    if (this.args.onSubmit) {
      return this.args.onSubmit(formInfo);
    }

    return {
      success: true,
      message: 'you need to provide your own submit function',
    };
  }

  get honeypot() {
    return this.args.honeypot ?? false;
  }

  get shouldReset() {
    return !this.args.noReset;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleError(_error: Error): void {
    // if (_error.message.includes('TaskCancelation')) {
    // }
    // SWALLOW ERROR FOR NOW
    // console.log('handling error');
    // console.log(error);
  }

  @action
  async submitForm(event: Event): Promise<FormResult | void> {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const task = this.processFormData;
    try {
      const taskInstance = await task.perform(target);
      return taskInstance;
    } catch (error: unknown) {
      return this.handleError(<Error>error);
    }
  }

  resetForm(event: Event): void {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    console.log(target.context.form);
    target.context.form.reset();
  }

  @action
  resetState(event?: Event): void {
    this.processFormData.cancelAll({ resetState: true });

    // run after render using ember runloop

    if (event) {
      schedule('afterRender', this, () => {
        this.resetForm(event);
      });
    }
  }

  processFormData = task(this, { drop: true }, async (target: HTMLFormElement): Promise<FormResult> => {
    const form = new FormData(target);

    const data: FormInfo = {
      data: Object.fromEntries(form.entries()),
      method: target.method,
      action: target.action,
    };

    try {
      if (this.timer) cancel(this.timer);
      const result = await (this.args.minTime ? pMinDelay(this.submit(data), this.args.minTime) : this.submit(data));

      if (this.shouldReset) {
        this.timer = later(() => {
          this.resetState();
        }, 10000);
      }

      if (result.success) {
        return result;
      } else {
        return Promise.reject(result);
      }
    } catch (error) {
      if (this.shouldReset) {
        this.timer = later(() => {
          this.resetState();
        }, 20000);
      }
      return Promise.reject(error);
    }
  });
}
