/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import styles from './styles.css';

interface XButtonArgs {
  action?(): void;
  route?: string;
  showSpinner?: string;
}

export default class XButton extends Component<XButtonArgs> {
  styles = styles;

  @action clickAction(): void {
    if (typeof this.args.action === 'function') {
      this.args.action();
    }
  }
}
