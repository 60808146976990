import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Registry, service } from '@ember/service';
import styles from './styles.css';

interface PagesVerifyEmailArgs {
  email: string;
  signature: string;
}

export default class PagesVerifyEmail extends Component<PagesVerifyEmailArgs> {
  @service api!: Registry['api'];
  styles = styles;

  @tracked isSuccessful = false;
  @tracked isVerifying = false;
  @tracked hasErrors = false;
  @tracked errorMessage = 'Something went wrong verifying your email address, please try again.';

  @action
  async verifyEmail() {
    this.isVerifying = true;
    try {
      const result = await this.api.verifyEmail({ email: this.args.email, signature: this.args.signature });
      this.isSuccessful = <boolean>result.json.success;
      if (!this.isSuccessful) {
        this.hasErrors = true;
        this.errorMessage = <string>result.json.message;
      }
    } catch {
      this.hasErrors = true;
      this.isSuccessful = false;
    }
    this.isVerifying = false;
  }
}
