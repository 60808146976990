import config from 'web-app/config/environment';
import Application from '@ember/application';
import 'ember-fast-marquee/marquee.css';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import './assets/styles.css';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
