/* eslint-disable @typescript-eslint/no-explicit-any */
// ember-simple-auth-token/authenticators/jwt is not typed
import { Registry, service } from '@ember/service';
import JWTAuthenticator from 'ember-simple-auth-token/authenticators/jwt';

export default class authenticator extends JWTAuthenticator {
  @service masterTab!: Registry['masterTab'];
  @service session!: Registry['session'];

  async refreshAccessToken(refreshToken: any, attempts: number) {
    const data = this.makeRefreshData(refreshToken);

    const masterTab = this.masterTab.run(() => {
      return this.makeRequest(this.serverTokenRefreshEndpoint, data, this.headers)
        .then((response: any) => {
          const sessionData = this.handleAuthResponse(response.json);
          this.trigger('sessionDataUpdated', sessionData);
          return sessionData;
        })
        .catch((error: any) => {
          this.handleTokenRefreshFail(error.status, refreshToken, attempts);
          return Promise.reject(error);
        });
    });

    masterTab.else(() => {
      this.masterTab.contestMasterTab();
    });

    return (await masterTab.result) ?? this.session.data;
  }
}
