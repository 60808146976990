import config from 'web-app/config/environment';
import EmberRouter from '@embroider/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('404', { path: '/*path' });
  this.route('application.index', { path: '/' });
  this.route('privacy-policy');
  this.route('terms-policy');
  this.route('client-policy');
  this.route('terms-policy-dari');
  this.route('complaints-policy');
  this.route('dr-waheed-arian');
  this.route('pathway', function () {
    this.route('questionnaire', { path: '/:pathway' });
  });
  this.route('login');
  this.route('forgotten-password');
  this.route('reset-password', { path: '/reset-password/:email/:token' });
  this.route('create-password', { path: '/create-password/:email/:token' });
  this.route('authenticated', { path: '/my-wellbeing' }, function () {
    this.route('appointments', function () {
      this.route('details', { path: '/:appointment_id' }, function () {
        this.route('confirm');
      });
    });
    this.route('account');
    this.route('information');
    this.route('forms');
  });
  this.route('verify-email', { path: '/verify-email/:email/:signature' });

  this.route('checkout', function () {
    this.route('case', { path: '/:case_cuid' }, function () {
      this.route('confirm');
    });
  });
  this.route('contact');
  this.route('organisations');
});
