/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
// To parse this data:
//
//   import { Convert, CaseModel } from "./file";
//
//   const caseModel = Convert.toCaseModel(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

// To parse this data:
//
//   import { Convert, CaseModel } from "./file";
//
//   const caseModel = Convert.toCaseModel(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface CaseModel {
  id: string;
  cuid: string;
  clientId: string;
  serviceProviderId: string;
  paymentStatus: string;
  cancelledAt: null;
  createdAt: Date;
  updatedAt: Date;
  appointments: Appointment[];
  client: Client;
  serviceProvider: ServiceProvider;
}

export interface Appointment {
  id: string;
  clientId: string;
  serviceProviderId: string;
  paymentStatus: string;
  start: Date;
  end: Date;
  createdAt: Date;
  updatedAt: Date;
  caseId: string;
  cost: number;
  serviceProvider: ServiceProvider;
}

export interface Client {
  id: string;
  cuid: string;
  createdAt: Date;
  updatedAt: Date;
  profile: Profile;
}

export interface ServiceProvider {
  id: string;
  cuid: string;
  createdAt: Date;
  updatedAt: Date;
  teamProfile?: TeamProfile;
}

export interface Profile {
  id: string;
  userId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TeamProfile {
  id: string;
  userId: string;
  avatar: Avatar | null;
  name: string;
  specialties: null | string;
  about: null | string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  costPerSession: number | null;
}

export interface Breakpoints {
  thumbnail: Avatar;
  large: Avatar;
  medium: Avatar;
  small: Avatar;
  xlarge: Avatar;
}

export interface Avatar {
  name: string;
  size: number;
  hash: string;
  width: number;
  format: string;
  height: number;
  extname: string;
  mimeType: string;
  breakpoints?: Breakpoints;
  url?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toCaseModel(json: string): CaseModel {
    return cast(JSON.parse(json), r('CaseModel'));
  }

  public static caseModelToJson(value: CaseModel): string {
    return JSON.stringify(uncast(value, r('CaseModel')), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
  const prettyTyp = typ ? prettyTypeName(typ) : null;
  const parentText = parent ? ` on ${parent}` : '';
  const keyText = key ? ` for key "${key}"` : '';
  throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a);
        })
        .join(', ')}]`;
    }
  } else if (typeof typ === 'object' && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {
        /* empty */
      }
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map((a) => {
        return l(a);
      }),
      val,
      key,
      parent
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l('array'), val, key, parent);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l('Date'), val, key, parent);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue(l(ref || 'object'), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === 'object' && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  CaseModel: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'cuid', js: 'cuid', typ: '' },
      { json: 'clientId', js: 'clientId', typ: '' },
      { json: 'serviceProviderId', js: 'serviceProviderId', typ: '' },
      { json: 'paymentStatus', js: 'paymentStatus', typ: '' },
      { json: 'cancelledAt', js: 'cancelledAt', typ: null },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
      { json: 'appointments', js: 'appointments', typ: a(r('Appointment')) },
      { json: 'client', js: 'client', typ: r('Client') },
      { json: 'serviceProvider', js: 'serviceProvider', typ: r('ServiceProvider') },
    ],
    false
  ),
  Appointment: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'clientId', js: 'clientId', typ: '' },
      { json: 'serviceProviderId', js: 'serviceProviderId', typ: '' },
      { json: 'paymentStatus', js: 'paymentStatus', typ: '' },
      { json: 'start', js: 'start', typ: Date },
      { json: 'end', js: 'end', typ: Date },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
      { json: 'caseId', js: 'caseId', typ: '' },
      { json: 'cost', js: 'cost', typ: u(0, null) },
      { json: 'serviceProvider', js: 'serviceProvider', typ: r('ServiceProvider') },
    ],
    false
  ),
  Client: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'cuid', js: 'cuid', typ: '' },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
      { json: 'organisationId', js: 'organisationId', typ: u(null, '') },
      { json: 'profile', js: 'profile', typ: r('Profile') },
    ],
    false
  ),
  ServiceProvider: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'cuid', js: 'cuid', typ: '' },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
      { json: 'organisationId', js: 'organisationId', typ: u(null, '') },
      { json: 'teamProfile', js: 'teamProfile', typ: u(undefined, r('TeamProfile')) },
    ],
    false
  ),
  Profile: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'userId', js: 'userId', typ: '' },
      { json: 'name', js: 'name', typ: '' },
      { json: 'lastName', js: 'lastName', typ: u(null, '') },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
    ],
    false
  ),
  TeamProfile: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'userId', js: 'userId', typ: '' },
      { json: 'avatar', js: 'avatar', typ: u(r('Avatar'), null) },
      { json: 'name', js: 'name', typ: '' },
      { json: 'lastName', js: 'lastName', typ: u(null, '') },
      { json: 'specialties', js: 'specialties', typ: u(null, '') },
      { json: 'about', js: 'about', typ: u(null, '') },
      { json: 'type', js: 'type', typ: '' },
      { json: 'createdAt', js: 'createdAt', typ: Date },
      { json: 'updatedAt', js: 'updatedAt', typ: Date },
      { json: 'costPerSession', js: 'costPerSession', typ: u(0, null) },
      { json: 'teamsUserId', js: 'teamsUserId', typ: u(null, '') },
    ],
    false
  ),
  Breakpoints: o(
    [
      { json: 'thumbnail', js: 'thumbnail', typ: r('Avatar') },
      { json: 'large', js: 'large', typ: r('Avatar') },
      { json: 'medium', js: 'medium', typ: r('Avatar') },
      { json: 'small', js: 'small', typ: r('Avatar') },
      { json: 'xlarge', js: 'xlarge', typ: r('Avatar') },
    ],
    false
  ),
  Avatar: o(
    [
      { json: 'name', js: 'name', typ: '' },
      { json: 'size', js: 'size', typ: 3.14 },
      { json: 'hash', js: 'hash', typ: '' },
      { json: 'width', js: 'width', typ: 0 },
      { json: 'format', js: 'format', typ: '' },
      { json: 'height', js: 'height', typ: 0 },
      { json: 'extname', js: 'extname', typ: '' },
      { json: 'mimeType', js: 'mimeType', typ: '' },
      { json: 'breakpoints', js: 'breakpoints', typ: u(undefined, r('Breakpoints')) },
      { json: 'url', js: 'url', typ: '' },
    ],
    false
  ),
};
