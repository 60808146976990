import { modifier } from 'ember-modifier';

function limitLengthInWords(field: HTMLTextAreaElement, maxWords: number, counterId: string) {
  const value = field.value;
  const wordCount = value.split(/\S+/).length - 1;
  const re = new RegExp('^\\s*\\S+(?:\\s+\\S+){0,' + (maxWords - 1) + '}');
  if (wordCount >= maxWords) {
    const match = value.match(re);
    if (match) {
      field.value = match.toString();
    }
  }
  const counter = document.getElementById(counterId);
  if (counter) {
    counter.innerHTML = '' + wordCount;
  }
}

export default modifier(function wordLimit(element: HTMLTextAreaElement, [maxWords, counterId]: [number, string]) {
  const handleEvent = () => {
    limitLengthInWords(element, maxWords, counterId);
  };

  handleEvent();
  element.addEventListener('paste', handleEvent, { passive: true });
  element.addEventListener('keyup', handleEvent, { passive: true });

  return () => {
    element.removeEventListener('paste', handleEvent);
    element.removeEventListener('keyup', handleEvent);
  };
});
