import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import Service from '@ember/service';
import { DateTime } from 'luxon';

export default class Clock extends Service {
  @tracked now = DateTime.now();
  resolution = 500;

  constructor() {
    super();
    this.tick();
  }

  tick(): void {
    later(() => {
      this.now = DateTime.now().set({ millisecond: 0 });

      this.tick();
    }, this.resolution);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    clock: Clock;
  }
}
