import { modifier } from 'ember-modifier';

interface Options {
  topOffset?: number;
  leftOffset?: number;
  behavior?: 'smooth' | 'auto';
}

interface NamedArguments {
  options?: Options;
  shouldScroll?: boolean;
  scrollSelector?: string;
}

export default modifier(function scrollIntoView(element, _positional, named: NamedArguments = {}) {
  const { options, shouldScroll, scrollSelector }: NamedArguments = named;
  let hasBeenRemoved: boolean;

  let scrollContainer: Element | Window = window;
  console.log('scroll');

  if (scrollSelector) {
    scrollContainer = document.querySelector(scrollSelector) || window;
  }

  const shouldScrollPromise = Promise.resolve(shouldScroll);

  shouldScrollPromise.then((shouldScrollValue) => {
    if (shouldScrollValue && element && !hasBeenRemoved) {
      if (!options) return;
      if (!options.topOffset && !options.leftOffset) {
        element.scrollIntoView(options);
      } else {
        const { topOffset, leftOffset } = options;
        let { behavior } = options;
        if (!behavior) {
          behavior = 'auto';
        }

        let scrollCurrentLeft = document.body.getBoundingClientRect().left;

        let scrollCurrentTop = document.body.getBoundingClientRect().top;

        if (scrollContainer !== window) {
          const container = scrollContainer as Element;
          scrollCurrentTop = -Math.abs(container.scrollTop);
          scrollCurrentLeft = -Math.abs(container.scrollLeft);
        }

        const left =
          leftOffset === undefined ? 0 : element.getBoundingClientRect().left - scrollCurrentLeft - leftOffset;

        const top = topOffset === undefined ? 0 : element.getBoundingClientRect().top - scrollCurrentTop - topOffset;

        scrollContainer?.scrollTo({
          behavior,
          top,
          left,
        });
      }
    }
  });

  return () => {
    hasBeenRemoved = true;
  };
});
