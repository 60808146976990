import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Service, { Registry, service } from '@ember/service';
import { DateTime } from 'luxon';

export interface User {
  id: string;
  email: string;
  verifiedAt: DateTime | string;
  createdAt: DateTime | string;
  updatedAt: DateTime | string;
  profile: Profile;
}

export interface Profile {
  id: string;
  userId: string;
  name?: string;
  dateOfBirth?: DateTime | string;
  pronouns?: string;
  ethnicity?: string;
  employmentStatus?: string;
  maritalStatus?: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressPostcode?: string;
  emergencyContactName?: string;
  emergencyContactRelation?: string;
  emergencyContactNumber?: string;
  gpName?: string;
  gpPracticeName?: string;
  gpNumber?: string;
  gpAddressLine1?: string;
  gpAddressLine2?: string;
  gpAddressCity?: string;
  gpAddressPostcode?: string;
  createdAt?: DateTime | string;
  updatedAt?: DateTime | string;
  gender?: string;
  languages?: string;
  worries?: string;
  noOfDependents?: number;
  stresses?: string;
  pains?: string;
  femalesGroup1?: number;
  femalesGroup2?: number;
  femalesGroup3?: number;
  malesGroup1?: number;
  malesGroup?: number;
  malesGroup3?: number;
  preferredTime?: string;
}

export default class CurrentUser extends Service {
  @service session!: Registry['session'];
  @service api!: Registry['api'];

  @tracked user!: User;
  @tracked profile!: Profile;

  @action
  async load() {
    if (this.session.isAuthenticated) {
      try {
        this.user = await this.api.whoami();
        this.user.createdAt = DateTime.fromISO(<string>this.user.createdAt);
        this.user.updatedAt = DateTime.fromISO(<string>this.user.updatedAt);
        this.user.profile.dateOfBirth = this.user.profile.dateOfBirth
          ? DateTime.fromISO(<string>this.user.profile.dateOfBirth)
          : undefined;
        this.user.profile.createdAt = DateTime.fromISO(<string>this.user.profile.createdAt);
        this.user.profile.updatedAt = DateTime.fromISO(<string>this.user.profile.updatedAt);
        this.profile = this.user.profile;
      } catch (error) {
        // swallow
      }
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'current-user': CurrentUser;
  }
}
