import { FetchResponse } from 'web-app';
import { Registry, service } from '@ember/service';
import SimpleAuthSessionService from 'ember-simple-auth/services/session';
import { FetchError } from './api';

export default class SessionService extends SimpleAuthSessionService {
  @service router!: Registry['router'];
  @service api!: Registry['api'];
  @service currentUser!: Registry['current-user'];

  handleAuthentication() {
    // we want to be able to silently login after registration
    // so we only do this transition if coming from a login
    if (!this.router.currentRoute.localName.includes('questionnaire')) {
      if (this?.attemptedTransition) {
        this.attemptedTransition.retry();
      } else {
        this.router.transitionTo('authenticated');
      }
    }
    this.loadCurrentUser();
  }

  // we need to use instanceof later on the catch the error returned bby the api
  // so we convert the response to an error instance
  async authenticate(authenticator: string, ...args: unknown[]): Promise<FetchResponse> {
    try {
      const result = <FetchResponse>await super.authenticate(authenticator, ...args);
      return result;
    } catch (error) {
      throw new FetchError(<FetchResponse>error);
    }
  }

  /**
   * Loads the current authenticated user
   */
  async loadCurrentUser() {
    try {
      const user = await this.currentUser.load();
      return user;
    } catch (err) {
      await this.invalidate();
    }
  }

  /**
   * revokes refresh token, clears local storage and refreshes the page.
   */
  // async invalidate(...args: unknown[]) {
  //   try {
  //     if (this.isAuthenticated) await this.api.logout(<string>this.data?.authenticated?.refreshToken);
  //   } catch {
  //     //swallow
  //   }
  //   super.invalidate(...args);
  // }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
