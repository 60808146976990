import Component from '@glimmer/component';
import { Registry, service } from '@ember/service';
import styles from './styles.css';

// interface GlobalFooterArgs {}

export default class GlobalFooter extends Component /*<GlobalFooterArgs>*/ {
  @service session!: Registry['session'];
  styles = styles;
  currentYear = new Date().getFullYear();
}
